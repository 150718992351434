@tailwind base;
@tailwind components;
@tailwind utilities;

.spinner {
	animation: spin infinite 3s linear;
	font-size: 3rem;
	margin: auto;
	display: block;
	/*You can increase or decrease the timer (5s) to 
	 increase or decrease the speed of the spinner*/
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.custom-card-image .card.image-full:before {
	border-radius: 0.5rem;
	opacity: 0.45;
}
